<template>
  <div>
    <div v-if="analyticsOptions.selectedId">
      <!-- **** -->
      <!-- CARDS -->
      <SpinnerLogo v-if="loadingAction.getAccountTypes" />
      <div v-else>
        <div class="row mb-4">
          <div v-for="{ icon, type } in consumptionCards.slice(0, 4)" :key="type" class="col">
            <ConsumptionCard
              :value="analytics[`${type}-consumption`]?.totalConsumption"
              :prev-value="analytics[`${type}-consumption-prev`]?.totalConsumption"
              precision="0"
              :icon-class="icon"
              :description="capitalize(type)"
              :units="unitMap(type)"
              :comparison-period="analyticsOptions.comparePeriod"
              class="flex-grow-1"
              :value-loading="loadingAction.getAnalytics[`${type}-consumption`]"
              :prev-value-loading="loadingAction.getAnalytics[`${type}-consumption-prev`]"
              :error="errorAction.getAnalytics[`${type}-consumption`]"
              :estimated-percentage="generateTotalEstimateConsumption(type) / analytics[`${type}-consumption`]?.totalConsumption"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div v-for="{ icon, type } in consumptionCards.slice(4, 8)" :key="type" class="col">
            <ConsumptionCard
              :value="analytics[`${type}-consumption`]?.totalConsumption"
              :prev-value="analytics[`${type}-consumption-prev`]?.totalConsumption"
              precision="0"
              :icon-class="icon"
              :description="capitalize(type)"
              :units="type === 'water' ? 'm3' : 'kWh'"
              :comparison-period="analyticsOptions.comparePeriod"
              class="flex-grow-1"
              :value-loading="loadingAction.getAnalytics[`${type}-consumption`]"
              :prev-value-loading="loadingAction.getAnalytics[`${type}-consumption-prev`]"
              :error="errorAction.getAnalytics[`${type}-consumption`]"
              :estimated-percentage="generateTotalEstimateConsumption(type) / analytics[`${type}-consumption`]?.totalConsumption"
            />
          </div>
        </div>
      </div>
      <!-- CARDS -->
      <!-- **** -->

      <!-- **** -->
      <!-- Consumption Chart -->
      <div class="bg-lighter rounded-md px-4 py-3 mb-4">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Granularity</div>
            <FormItem
              id="granularity"
              v-model="analyticsOptions.granularity"
              class="mb-0"
              type="select"
              :options="granularityOptions"
              :disabled="loadingAction.getConsumptions"
              @input="onGranularityUpdate"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Utility Type</div>
            <FormItem
              id="accountType"
              v-model="analyticsOptions.accountType"
              class="mb-0"
              type="select"
              :options="typeOptions"
              @input="onTypeUpdate"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Highlight</div>
            <FormItem
              id="highlight"
              v-model="filters.highlight"
              class="mb-0"
              type="select"
              :options="[
                { label: 'None', value: '' },
                { label: 'Source', value: 'source' },
                { label: 'Estimated', value: 'estimated' },
                { label: 'Compare To', value: 'compare' }
              ]"
              :disabled="loadingAction.getConsumptions"
              @input="refreshChart"
            />
          </div>
          <div class="d-flex align-items-center mr-3 border-right pr-3">
            <div class="form-static-text pr-3">Graph Type</div>
            <a
              href="#"
              class="font-w600 px-3 py-1"
              :class="{ 'bg-primary text-white border rounded-md': filters.chartType === 'bar' }"
              @click.prevent="filters.chartType = 'bar'"
              ><i class="fa-solid fa-chart-column mr-1"></i> Bar</a
            >
            <a
              href="#"
              class="font-w600 px-3 py-1"
              :class="{ 'bg-primary text-white border rounded-md': filters.chartType === 'line' }"
              @click.prevent="filters.chartType = 'line'"
              ><i class="fa-solid fa-chart-line mr-1"></i> Line</a
            >
          </div>
        </div>
      </div>
      <Chart
        v-if="!loading && !loadingAction.getAnalytics['consumption'] && analytics['consumption']?.data?.length"
        class="mb-4"
        style="height: 500px"
        :option="consumptionChart"
      />
      <SpinnerLogo v-else />
      <!-- Consumption Chart -->
      <!-- **** -->
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Chart from '@/components/echarts/Chart';
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import FormItem from '@/components/FormItem';
import SpinnerLogo from '@/components/SpinnerLogo';

import { capitalize } from '@/lib/helpers';
import { granularityFormatMap } from '../../lib/consumption';
import chartOptions from '@/views/Machine/templates/charts/options';

export default {
  name: 'AnalyticsConsumption',
  components: {
    Chart,
    ConsumptionCard,
    FormItem,
    SpinnerLogo
  },
  data() {
    return {
      loading: false,
      filters: {
        highlight: '',
        chartType: 'bar'
      }
    };
  },
  computed: {
    ...mapGetters({
      analytics: 'analytics/analytics',
      analyticsOptions: 'analytics/options',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      accounts: 'asset/accounts',
      accountTypes: 'analytics/accountTypes',
      units: 'util/units'
    }),
    consumptionCards() {
      const icons = {
        gas: 'fa-flame text-primary',
        electricity: 'fa-plug text-primary',
        water: 'fa-water text-primary',
        solar: 'fa-solar-panel text-primary',
        heating: 'fa-heat text-warning',
        cooling: 'fa-fan text-info',
        waste: 'fa-waste'
      };
      return this.accountTypes.map(type => ({
        type,
        icon: icons[type]
      }));
    },
    typeOptions() {
      return (this.accountTypes || [])
        .map(type => ({
          label: capitalize(type),
          value: type
        }))
        .concat({
          label: 'All',
          value: ''
        });
    },
    granularityOptions() {
      return Object.entries(granularityFormatMap).map(([key, value]) => ({
        label: value.label,
        value: key
      }));
    },
    consumptionChart() {
      const gasConsumptionData = this.analytics['gas-consumption']?.data || [];
      const waterConsumptionData = this.analytics['water-consumption']?.data || [];
      const solarConsumptionData = this.analytics['solar-consumption']?.data || [];
      const elecConsumptionData = this.analytics['electricity-consumption']?.data || [];
      const heatingConsumptionData = this.analytics['heating-consumption']?.data || [];
      const coolingConsumptionData = this.analytics['cooling-consumption']?.data || [];
      const allConsumption = this.analytics['consumption']?.data || [];

      const consumptionDataMap = {
        gas: gasConsumptionData,
        electricity: elecConsumptionData,
        water: waterConsumptionData,
        solar: solarConsumptionData,
        heating: heatingConsumptionData,
        cooling: coolingConsumptionData
      };

      const data = allConsumption.map(c => ({
        ...c,
        date: this.generateDate(c.date)
      }));

      const baseOptions = {};

      const baseSeriesOptions = {
        type: this.filters.chartType
      };

      if (this.filters.chartType === 'line') {
        baseSeriesOptions.smooth = false;
      }

      const totalConsumptionSeries = {
        ...baseSeriesOptions,
        name: 'Consumption',
        data: data.map(m => [m.date, m.consumption])
      };

      let series = [];

      if (!this.analyticsOptions.accountType) {
        // if account type is set to "All", show all consumption types
        series = this.accountTypes.reduce((acc, type) => {
          const consumptionData = consumptionDataMap[type];
          return [
            ...acc,
            {
              ...baseSeriesOptions,
              name: capitalize(type),
              data: consumptionData.map(m => [this.generateDate(m.date), m.consumption])
            }
          ];
        }, []);
        baseOptions.legend = {};
      } else {
        series = [totalConsumptionSeries];
      }

      const highlightOptions = { ...baseSeriesOptions, stack: this.filters.chartType === 'bar' ? 'a' : null };

      if (this.filters.highlight === 'source') {
        if (!this.analyticsOptions.accountType) {
          // if account type is set to "All", show all consumption types
          series = this.accountTypes.reduce((acc, type) => {
            const consumptionData = consumptionDataMap[type];
            return [...acc, ...this.generateSourceData(consumptionData, type, highlightOptions)];
          }, []);
          delete baseOptions.legend;
        } else {
          series = this.generateSourceData(data, '', highlightOptions);
          baseOptions.legend = {};
        }
      } else if (this.filters.highlight === 'estimated') {
        if (!this.analyticsOptions.accountType) {
          // if account type is set to "All", show all consumption types
          series = this.accountTypes.reduce((acc, type) => {
            const consumptionData = consumptionDataMap[type];
            const estimatedConsumptionSeries = this.generateEstimatedSeries(consumptionData, `Estimated ${capitalize(type)}`, highlightOptions);
            const actualConsumptionSeries = this.generateActualSeries(consumptionData, `Actual ${capitalize(type)}`, highlightOptions);
            return [...acc, estimatedConsumptionSeries, actualConsumptionSeries];
          }, []);
        } else {
          const estimatedConsumptionSeries = this.generateEstimatedSeries(data, 'Estimated', baseSeriesOptions);
          const actualConsumptionSeries = this.generateActualSeries(data, 'Actual', baseSeriesOptions);
          series = [estimatedConsumptionSeries, actualConsumptionSeries];
        }

        baseOptions.legend = {};
      } else if (this.filters.highlight === 'compare') {
        const prevConsumptionData = this.analytics['consumption-prev']?.data || [];
        let prevConsumptionSeries = [this.generatePrevSeries(prevConsumptionData, 'Prev Period Consumption', baseSeriesOptions, data)];

        if (!this.analyticsOptions.accountType) {
          prevConsumptionSeries = this.accountTypes.reduce((acc, type) => {
            const consumptionData = this.analytics[`${type}-consumption-prev`]?.data || [];
            const prevConsumptionSeries = this.generatePrevSeries(consumptionData, `Prev Period ${capitalize(type)}`, baseSeriesOptions, data);
            return [...acc, prevConsumptionSeries];
          }, []);
        }

        series = [...series, ...prevConsumptionSeries];
      }

      return {
        ...chartOptions,
        ...baseOptions,
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            const s = params
              .map(param => {
                const series = param.seriesName.toLocaleString();
                const value = param.value[1] !== null ? param.value[1].toLocaleString() : null;
                const valueHtml = value
                  ? `<strong>${value}</strong> ${this.analyticsOptions.accountType === 'water' || series === 'Water' ? 'm<sup>3</sup>' : 'kWh'}`
                  : '<strong>No data</strong>';
                return `<i class="fa fa-circle fa-fw fa-sm mr-1" style="color: ${param.color}"></i>${series}: ${valueHtml}`;
              })
              .join('<br />');

            const date = moment.utc(params[0].axisValue).format(granularityFormatMap[this.analyticsOptions.granularity].format);

            return `<strong>${date}</strong><br /><br />${s}`;
          },
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          minInterval: granularityFormatMap[this.analyticsOptions.granularity].minTick
        },
        yAxis: {
          name: this.analyticsOptions.accountType === 'water' ? 'm3' : 'kWh',
          type: 'value'
        },
        series
      };
    }
  },
  methods: {
    ...mapActions({
      refresh: 'analytics/refreshGraphs'
    }),
    ...mapMutations({
      setOption: 'analytics/SET_OPTION'
    }),
    unitMap(type) {
      return (this.units || []).find(u => u.defaultTypes?.includes(type))?.name;
    },
    generateTotalEstimateConsumption(type) {
      const data = this.analytics[`${type}-consumption`]?.data || [];
      return data.reduce((acc, m) => acc + m.estimatedConsumption, 0) * 100;
    },
    generateSourceData(data, type, options) {
      const invoiceData = this.generateInvoiceSeries(data, `Invoices ${capitalize(type)}`, options);
      const hhData = this.generateHHSeries(data, `HH ${capitalize(type)}`, options);
      const customData = this.generateCustomSeries(data, `Custom ${capitalize(type)}`, options);
      const readingData = this.generateReadingSeries(data, `Readings ${capitalize(type)}`, options);
      return [invoiceData, hhData, customData, readingData];
    },
    generateDate(date) {
      return this.analyticsOptions.granularity === 'quarterly' ? moment(date).add(1, 'month').toDate() : moment(date).toDate();
    },
    generateInvoiceSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          const invoice = m.combinedBreakdown?.invoice || null;
          return [m.date, invoice];
        })
      };
    },
    generateReadingSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          const reading = m.combinedBreakdown?.reading || null;
          const readingA = m.combinedBreakdown?.readingA || null;
          const readingE = m.combinedBreakdown?.readingE || null;
          return [m.date, reading + readingA + readingE];
        })
      };
    },
    generateCustomSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          const customA = m.combinedBreakdown?.customA || null;
          const customE = m.combinedBreakdown?.customE || null;
          return [m.date, customA + customE];
        })
      };
    },
    generateHHSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          const hh = m.combinedBreakdown?.hh || null;
          return [m.date, hh];
        })
      };
    },
    generatePrevSeries(data, name, baseSeriesOptions, dataWithDate) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map((m, index) => {
          const date = dataWithDate[index]?.date;
          return [date, m.consumption];
        })
      };
    },
    generateActualSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          const invoiceA = m.combinedBreakdown?.invoiceA || null;
          const customA = m.combinedBreakdown?.customA || null;
          const readingA = m.combinedBreakdown?.readingA || null;
          const hh = m.combinedBreakdown?.hh || null;
          return [m.date, hh + customA + invoiceA + readingA];
        })
      };
    },
    generateEstimatedSeries(data, name, baseSeriesOptions) {
      return {
        ...baseSeriesOptions,
        name,
        data: data.map(m => {
          return [m.date, m.estimatedConsumption];
        })
      };
    },
    capitalize,
    onGranularityUpdate(granularity) {
      this.setOption({ key: 'granularity', option: granularity });
      this.refresh();
    },
    onTypeUpdate(type) {
      this.setOption({ key: 'accountType', option: type });
      this.refresh();
    },
    refreshChart() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
};
</script>
