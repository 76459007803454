var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ConsumptionCard bg-lighter rounded-md p-3",
    class: {
      'ConsumptionCard--report': _vm.isReport
    }
  }, [_vm.error ? _c('div', [_vm._m(0)]) : _vm.valueLoading ? _c('SpinnerLogo', {
    staticClass: "px-5"
  }) : _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('div', {
    staticClass: "ConsumptionCard--description",
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(_vm._s(_vm.description))]), _c('div', {
    staticClass: "ConsumptionCard--header"
  }, [_vm.prefix ? _c('small', {
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(_vm._s(_vm.prefix))]) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.formattedValue, _vm.precision)) + " "), _c('small', {
    class: {
      'text-muted': !_vm.isReport
    },
    domProps: {
      "innerHTML": _vm._s(_vm.formattedUnits)
    }
  })]), !_vm.hidePrevValue ? _c('PctDiff', {
    staticClass: "ConsumptionCard--delta",
    attrs: {
      "value": _vm.value,
      "prev-value": _vm.prevValue,
      "prev-value-loading": _vm.prevValueLoading,
      "inverse": _vm.inverse || _vm.inverseColor,
      "comparison-period": _vm.comparisonPeriod,
      "is-report": _vm.isReport
    }
  }) : _vm._e(), _vm.estimatedPercentage ? _c('small', {
    staticClass: "ml-2",
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(" |")]) : _vm._e(), _vm.estimatedPercentage ? _c('span', {
    staticClass: "ConsumptionCard--estimated ml-1 pt-2",
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(" " + _vm._s(_vm.estimatedPercentage.toFixed(2)) + "% estimated")]) : _vm._e()], 1), !_vm.hideIcon ? _c('div', {
    staticClass: "mx-3"
  }, [_c('i', {
    staticClass: "fa fa-2x",
    class: _vm.iconClass
  })]) : _vm._e()])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Error loading data.")])]);

}]

export { render, staticRenderFns }