import moment from 'moment';

export const prepareInvoiceConsumptionForDB = (consumptionBase, data, startDate, endDate) => {
  // Apportion waste consumption into months (if start and end date cover multiple months)

  const consumptionData = [];

  // const consumptionBase = {
  //   source: 'invoice',
  //   accountId: this.account._id,
  //   entityId: this.account.entityId,
  //   companyId: this.account.companyId,
  //   invoiceId: createdInv._id
  // };

  // const globalStartDate = moment(createdInv.values.startDate);
  // const globalEndDate = moment(createdInv.values.endDate);

  const consumptionValues = Object.entries(data)
    .filter(([, value]) => value.value !== null && value.value !== '')
    .map(([category, value]) => ({
      startTimestamp: moment(startDate).endOf('day'),
      endTimestamp: moment(endDate).endOf('day'),
      timestamp: moment(startDate).endOf('day'),
      value: parseFloat(value.value),
      unit: value.unit,
      category: category,
      readType: 'A',
      ...consumptionBase
    }));

  consumptionData.push(...consumptionValues);

  return consumptionData;
};

export const granularityFormatMap = {
  halfhourly: { format: 'Do MMM YYYY HH:mm', minTick: 1000 * 60, label: 'Half Hourly', type: 'time' },
  hourly: { format: 'Do MMM YYYY HH:mm', minTick: 1000 * 60 * 60, label: 'Hourly', type: 'time' },
  daily: { format: 'Do MMM YYYY', minTick: 1000 * 60 * 60 * 24, label: 'Daily', type: 'time' },
  weekly: { format: 'Do MMM YYYY', minTick: 1000 * 60 * 60 * 24, label: 'Weekly', type: 'time' },
  monthly: { format: 'MMM YYYY', minTick: 1000 * 60 * 60 * 24 * 27, label: 'Monthly', type: 'time' },
  quarterly: { format: '[Q]Q YYYY', minTick: 1000 * 60 * 60 * 24 * 150, label: 'Quarterly', type: 'category' },
  yearly: { format: 'YYYY', minTick: 1000 * 60 * 60 * 24 * 365, label: 'Yearly', type: 'category' }
};

export const chartColours = [
  '#65C198',
  '#1F303D',
  '#F25F5C',
  '#FFE066',
  '#1B98E0',
  '#91cc75',
  '#c91466',
  '#fac858',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#f6c85f',
  '#3e97d1',
  '#6f9eaf',
  '#f6b26b',
  '#b6a2de',
  '#affffa',
  '#aaaaaa',
  '#444444',
  '#ffff00',
  '#f4b7da',
  '#4da9c9',
  '#fda222',
  '#4da555'
];

export const chartConsumptionOptions = {
  color: chartColours,
  dataZoom: [
    {
      type: 'inside'
    },
    {
      type: 'slider',
      dataBackground: {
        lineStyle: {
          color: '#65C198',
          opacity: 0.2
        },
        areaStyle: {
          color: '#65C198',
          opacity: 0.2
        }
      },
      selectedDataBackground: {
        lineStyle: {
          color: '#65C198',
          opacity: 0.5
        },
        areaStyle: {
          color: '#65C198',
          opacity: 0.5
        }
      },
      fillerColor: 'rgba(101, 193, 152, 0.2)',
      borderColor: '#65C198',
      moveHandleStyle: {
        color: 'rgba(101, 193, 152, 0.7)'
      }
    }
  ],
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none'
      },
      restore: {},
      saveAsImage: {}
    }
  },
  animationEasing: 'elasticOut'
};

export const calculateEmissionData = (
  { scope1: scope1Current, scope2: scope2Current, scope3: scope3Current, total: totalCurrent },
  { scope1: scope1Prev, scope2: scope2Prev, scope3: scope3Prev, total: totalPrev }
) => ({
  scope1: scope1Current + scope1Prev,
  scope2: scope2Current + scope2Prev,
  scope3: scope3Current + scope3Prev,
  total: totalCurrent + totalPrev
});

export const combineEmissionData = (emissionData, accEmissions) => {
  if (!emissionData) return accEmissions;

  const { totalEmissions, data: granularityData } = emissionData;
  if (!granularityData || !totalEmissions) return accEmissions;

  const totalAndScopes = calculateEmissionData(accEmissions, totalEmissions);

  const { data: accEmissionsData } = accEmissions;
  if (!accEmissionsData.length) {
    return { data: granularityData, ...totalAndScopes };
  }

  const calculatedGranularityData = granularityData.map(({ emissions: granularityEmissions, date }) => {
    const { emissions: matchedGranularityEmissions } = accEmissionsData.find(({ date: currentDate }) => currentDate === date);

    const newEmissions = calculateEmissionData(granularityEmissions, matchedGranularityEmissions);

    return { emissions: newEmissions, date };
  });

  return { ...totalAndScopes, data: calculatedGranularityData };
};

export const benchmarkConsumptionLineChart = ({
  elecConsumption = [],
  gasConsumption = [],
  floorAreaMetric,
  startDate = moment().subtract(1, 'year'),
  endDate = moment()
}) => {
  const totalConsumptionSeries = elecConsumption.map(data => {
    const gas = gasConsumption.find(g => g.date === data.date)?.consumption || 0;
    return [new Date(data.date), (data.consumption + gas) / floorAreaMetric];
  });

  if (totalConsumptionSeries.length === 0) {
    const startDateCloned = startDate.clone();
    while (startDateCloned.isBefore(endDate)) {
      totalConsumptionSeries.push([new Date(startDateCloned.format()), 0]);
      startDateCloned.add(1, 'month');
    }
  }

  const max = Math.max(...totalConsumptionSeries.map(s => s[1]));

  const elecSeries = elecConsumption.map(m => [new Date(m.date), m.consumption / floorAreaMetric]);
  const gasSeries = gasConsumption.map(m => [new Date(m.date), m.consumption / floorAreaMetric]);

  const mainParams = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    colors: ['#727272', '#fcb119', '#e04f1b'],
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontFamily: 'Inter, Arial, sans-serif',
          fontWeight: 600
        }
      },
      forceNiceScale: true
    },
    yaxis: {
      title: {
        text: 'kWh/m²'
      },
      decimalsInFloat: 0,
      labels: {
        style: {
          fontFamily: 'Inter, Arial, sans-serif',
          fontWeight: 600
        }
      },
      max: max < 20 ? 20 : Math.ceil(max + 5)
    },
    legend: {
      position: 'bottom'
    },
    fill: {
      opacity: 1
    },
    stroke: {
      width: 3,
      colors: undefined
    },
    dataLabels: {
      enabled: false
    },
    annotations: {
      yaxis: [
        {
          y: 124 / 12,
          strokeDashArray: 6,
          stroke: 3,
          borderColor: '#82b54b',
          borderWidth: 2,
          label: {
            borderColor: 'transparent',
            style: {
              color: '#82b54bbb',
              background: 'transparent',
              fontWeight: 'bold'
            },
            text: 'REEB - Office Air Conditioned (Good)'
          }
        },
        {
          y: 174 / 12,
          strokeDashArray: 6,
          stroke: 3,
          borderColor: '#fcb119',
          borderWidth: 2,
          label: {
            borderColor: 'transparent',
            style: {
              color: '#fcb119bb',
              background: 'transparent',
              fontWeight: 'bold'
            },
            text: 'REEB - Office Air Conditioned (Typical)'
          }
        },
        {
          y: 55 / 12,
          strokeDashArray: 6,
          stroke: 3,
          borderColor: '#1B98E0',
          borderWidth: 2,
          label: {
            borderColor: 'transparent',
            style: {
              color: '#1B98E0BB',
              background: 'transparent',
              fontWeight: 'bold'
            },
            text: 'LETI - Commercial Offices'
          }
        },
        {
          y: 201.7 / 12,
          strokeDashArray: 6,
          stroke: 3,
          borderColor: '#945ab9',
          borderWidth: 2,
          label: {
            borderColor: 'transparent',
            style: {
              color: '#945ab9bb',
              background: 'transparent',
              fontWeight: 'bold'
            },
            text: 'CRREM - Office 1.5°',
            offsetY: -5
          }
        }
      ]
    }
  };

  return {
    // elec: {
    //   options: mainParams,
    //   series: [{ data: analytics['elec-consumption']?.results?.monthlyConsumption.map(m => [new Date(m.period), m.consumption]) }]
    // },
    options: {
      ...mainParams
    },
    series: [
      { name: 'Total', data: totalConsumptionSeries },
      { name: 'Elec', data: elecSeries },
      { name: 'Gas', data: gasSeries }
    ]
  };
};
